<template>
  <div>
    合作服务商
  </div>
</template>

<script>
export default {
  name: 'serviceProvider'
}
</script>


<style scoped lang="less">

</style>
